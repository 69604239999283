<template>
  <div class="app">
    <PcTrgp v-if="pcIpone"/>
    <MobileTrgp v-if="!pcIpone"/>
  </div>
</template>

<script>
import PcTrgp from './components/PcTrgp.vue'
import MobileTrgp from './components/MobileTrgp.vue';

export default {
  name: 'App',
  components: {
    PcTrgp,
    MobileTrgp
  },
  data() {
    return {
      pcIpone: false
    }
  },
  mounted() {
    var ua = navigator.userAgent,
        isWindowsPhone = /(?:Windows Phone)/.test(ua),
        isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
        isAndroid = /(?:Android)/.test(ua),
        isFireFox = /(?:Firefox)/.test(ua),
        isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(
            ua)),
        isPhone = /(?:iPhone)/.test(ua) && !isTablet,
        isPc = !isPhone && !isAndroid && !isSymbian && !isTablet;
    this.pcIpone = isPc;
  }
}
</script>

<style lang="less">
body,
html {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: pingfang-y;
}

.app {
  font-family: pingfang-y;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  overflow: hidden;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.el-message {
  position: absolute !important;
  left: 50%;
  transform: translateX(-50%);
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
  Bitstream Vera Sans Mono, Courier New, monospace !important;
  padding: 2px 2px !important;
  min-width: 13px !important;

  .el-message__icon {
    margin-right: 2px !important;
    font-size: 7px !important;
  }

  .el-message__content {
    font-size: 5px !important;
  }
}

.el-loading-mask {
  .circular {
    width: 12px !important;
    height: 12px !important;
  }

  .el-loading-text{
    font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace !important;
    font-size: 6px !important;
  }
}
</style>
