<template>
  <div>
    <div class="y_main">
      <div class="wallet-connect-wrapper">
        <w3m-button></w3m-button>
        <img src="../assets/Ymain/twitter.png"
             @click="_jump"
             style="width: 30px;height: 30px;position: absolute;left: -50px;cursor: pointer;top: 50%;transform: translateY(-50%)" />
      </div>
      <div class="y_name">
        <div>DANCING DRAGON</div>
        <div><img src="../assets//Ymain/title.png" alt=""></div>
        <div style="font-size: 2rem">Build on Zkfair</div>
      </div>
      <div class="y_dis">
        <div>
          <img src="../assets//Ymain/t_1.png" alt="">
        </div>
        <div><img src="../assets//Ymain/t_2.png" alt=""></div>
      </div>
      <div class="y_set">
        <div>
          <div class="y_main_cen">
            <div class="Y_vc">
<!--              <video controls src="../assets/Ymain/2.mp4" autoPlay loop muted></video>-->
              <img style="height: 100%" src="../assets/Ymain/1.png" alt="">
            </div>
            <div class="y_al">
              <div class="y_as">FIRE MINT Dragon Stamp</div>
              <div class="y_time">
                OPENIN
                <div>
                  {{ dateJson['dd'] }}
                </div>
                <span>D</span>
                <div>
                  {{ dateJson['hh'] }}
                </div>
                <span>H</span>
                <div>
                  {{ dateJson['mm'] }}
                </div>
                <span>M</span>
              </div>
              <div class="y_pf" style="position: relative">
                <div style="margin: 0">PRIZE {{ (0.1 * amount).toFixed(1) }} U</div>
                <div style="position: absolute;left: 110px;white-space: nowrap;">Max 100 NFT/Mint</div>
              </div>
            </div>
            <div class="y_add">
              <div class="y_add1">
                <div @click="decrement" style="cursor: pointer;flex: 1;display: flex;justify-content: center">-</div>
                <input style="flex: 2;display: flex;justify-content: center"
                       type="number"
                       :value="amount"
                       :min="1"
                       :max="200"
                       @change="handleAmountChange"/>
                <div @click="increment" style="cursor: pointer;flex: 1;display: flex;justify-content: center">+</div>
              </div>
              <div class="y_add2" @click="_mint">MINT</div>
            </div>
            <div class="y_o">
              <div class="yo_div"></div>
              <div class="yo_div1">
                <div>TOTAL MINTED</div>
                <div>{{ totalSupply }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Contract, ethers} from "ethers";
import {createWeb3Modal, defaultConfig} from '@web3modal/ethers5'
import ProxyDeployments from "../config/proxy.json";
import Erc721Deployments from "../config/erc721.json";
import {Loading} from 'element-ui';

let loadingInstance = null;

const projectId = '792c9f0a43931183f82c7c2b5ec5d8e4'
const mainnet = {
  chainId: 42766,
  name: 'ZKFair',
  currency: 'USDC',
  explorerUrl: 'https://scan.zkfair.io',
  rpcUrl: 'https://rpc.zkfair.io'
}

const metadata = {
  name: 'Dancing Dragon',
  description: 'Dancing Dragon',
  url: 'https://dancingdragon.org/', // origin must match your domain & subdomain
  icons: ['https://avatars.mywebsite.com/']
}

const modal = createWeb3Modal({
  ethersConfig: defaultConfig({metadata}),
  chains: [mainnet],
  projectId,
  enableAnalytics: true // Optional - defaults to your Cloud configuration
})

Promise.sleep = (s) => {
  return new Promise(resolve => {
    setTimeout(resolve, s)
  })
}

export default {
  name: 'PcTrgp',
  data() {
    return {
      dateJson: {
        "dd": "00",
        "hh": "00",
        "mm": "00"
      },
      amount: 1,
      userAccount: "",
      totalSupply: "0"
    }
  },
  created() {
    modal.subscribeEvents(event => {
      if (event && event.data && event.data.event === "CONNECT_ERROR") {
        const {message} = event.data.properties
        if (message === 'ChainId not found' || message === 'Requested chains are not supported') {
          this.$message({
            message: 'Please switch to ZKFair network',
            type: 'warning'
          });
        }
      }
    })

    if (window.ethereum) {
      window.ethereum.on("accountsChanged", function () {
        window.location.reload();
      });
    }

    this._getLastTokenId()
    this._getCurrentBlockTimestamp()
  },
  methods: {
    _jump(){
      window.open(`https://twitter.com/DanceDragonNFT`)
    },
    async _getCurrentBlockTimestamp() {
      const provider = new ethers.providers.JsonRpcProvider('https://rpc.zkfair.io')
      // 获取最新的区块号
      let blockNumber = ''
      // 获取区块详情
      let block = ''

      try {
        blockNumber = await provider.getBlockNumber();
        block = await provider.getBlock(blockNumber);
      } catch (e) {
        await Promise.sleep(1000)
        return this._getCurrentBlockTimestamp()
      }

      let curTime = block.timestamp * 1000;
      let startTime = 1708930800 * 1000

      const next = async () => {
        let timeLeft = startTime - curTime
        if (timeLeft > 0) {
          const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
          const hours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);
          const minutes = Math.floor((timeLeft / 1000 / 60) % 60);

          this.dateJson = {
            dd: days.toString().padStart(2, '0'),
            hh: hours.toString().padStart(2, '0'),
            mm: minutes.toString().padStart(2, '0')
          };
        } else {
          this.dateJson = {
            "dd": "00",
            "hh": "00",
            "mm": "00"
          }
        }

        await new Promise((resolve) => {
          setTimeout(() => {
            curTime += 1000
            resolve()
          }, 1000)
        })

        await next()
      }

      await next()
    },
    async _getLastTokenId() {
      const provider = new ethers.providers.JsonRpcProvider('https://rpc.zkfair.io')
      const Erc721Contract = new Contract(Erc721Deployments.address, Erc721Deployments.abi, provider);
      try {
        const totalSupply = await Erc721Contract.totalSupply()
        this.totalSupply = totalSupply.toString()
      } catch (e) {
        await Promise.sleep(1000)
      }

      await Promise.sleep(5000)
      return this._getLastTokenId()
    },
    handleAmountChange(e) {
      const newValue = e.target.value;
      if (newValue > 110) {
        this.amount = 110;
        return
      }

      if (newValue < 0) {
        this.amount = 0
        return
      }

      if (/^\d+$/.test(newValue) && newValue >= 1) {
        this.amount = parseInt(newValue, 10);
      }
    },
    async _mint() {
      const isConnected = modal.getIsConnected()
      if (!isConnected) {
        return modal.open()
      }

      const address = modal.getAddress()
      if (!address) {
        return modal.open()
      }

      if (!(this.dateJson['dd'] === '00' && this.dateJson['hh'] === '00' && this.dateJson['mm'] === '00')) {
        this.$message({
          message: 'Minting has not started',
          type: 'warning'
        });
        return
      }

      this.userAccount = address

      const nftPrice = ethers.utils.parseEther("0.1"); // 单个NFT的价格
      const totalCost = nftPrice.mul(this.amount);
      const walletProvider = modal.getWalletProvider()
      const ethersProvider = new ethers.providers.Web3Provider(walletProvider)
      const signer = await ethersProvider.getSigner()
      const ProxyContract = new Contract(ProxyDeployments.address, ProxyDeployments.abi, signer);

      loadingInstance = Loading.service({
        fullscreen: true,
        text:"Batch mint, please wait about 30 seconds for wallet signing, don't refresh"
      });

      await ProxyContract.estimateGas.mint(this.userAccount, this.amount, {
        value: totalCost
      }).catch(e => {
        if (e.message && (e.message.includes("reverted: Insufficient") || e.message.includes('insufficient funds for intrinsic'))) {
          this.$message({
            message: 'Insufficient USDC sent',
            type: 'warning'
          });
        }

        loadingInstance && loadingInstance.close()
        throw e;
      })

      await ProxyContract.mint(this.userAccount, this.amount, {
        value: totalCost
      }).catch(e => {
        if (e.message && e.message.includes("user rejected transaction")) {
          this.$message({
            message: 'User denied transaction signature',
            type: 'warning'
          });
        }

        loadingInstance && loadingInstance.close()
        throw e
      })

      loadingInstance && loadingInstance.close()

      this.$message({
        message: 'Congratulations on successful minting',
        type: 'success'
      });
    },
    increment() {
      if (this.amount < 110) {
        this.amount += 1;
      }
    },
    decrement() {
      if (this.amount > 1) {
        this.amount -= 1;
      }
    }
  }
}
</script>

<style scoped lang="less">
.y_main {
  width: 100%;
  height: 100vh;
  background: url(../assets/Ymain/bag.png) center no-repeat;
  background-size: 100% 100%;
  overflow: hidden;

  .wallet-connect-wrapper {
    position: absolute;
    top: 60px;
    right: 10px;
    background: #ffffff !important;
    border-radius: 30px;
  }

  .y_name {
    text-align: center;
    font-size: 40px;
    color: rgb(76, 72, 73);
    padding-top: 15px;

  }

  .y_dis {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: auto;

    img {
      width: 50px;
      height: 80px;
    }
  }

  .y_set {
    width: 90%;
    margin: auto;

    div {
      margin: auto;
    }

    .y_img1 {
      text-align: right;
    }

    .Y_v {
      background: url(../assets//Ymain/life.png) center no-repeat;
      background-size: 100% 100%;
      width: 18vw;
      height: 30vw;
      display: flex;
      align-items: center;
      justify-content: center;

      video {
        width: 95%;
        height: 93%;
      }
    }

    .Y_vc {
      // background: url(../assets//Ymain/center.png) center no-repeat;
      background-size: 100% 100%;
      width: 100%;
      height: 200px;
      //border: 0.5vw solid white;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;

      video {
        width: 99%;
        height: 98%;
      }


    }

    .y_al {
      width: 100%;
      height: 120px;
      background: url(../assets/Ymain/mint1.png) center no-repeat;
      background-size: 100% 100%;
      padding-top: 10px;
      margin-top: 2px;

      .y_as {
        font-size: 16px;
        width: 84%;
        padding-top: 10px;
      }

      .y_time {
        display: flex;
        align-items: center;
        font-size: 16px;
        margin: revert;
        margin-left: 25px;
        width: 54%;

        div {
          width: 50px;
          height: 30px;
          background: url(../assets/Ymain/time.png) center no-repeat;
          background-size: 100% 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .y_pf {
        font-size: 16px;
        width: 54%;
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 25px;
      }
    }

    .y_add {
      width: 85%;
      margin: auto;
      margin-top: 15px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      .y_add1 {
        width: 100px;
        height: 25px;
        background: url(../assets//Ymain/mint3.png) center no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;

        > input {
          font-family: 'pingfang-y' !important;
          font-size: 4vw !important;
          background: transparent !important;
          text-align: center;
          border: none !important;
          //width: 10px;
        }

        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }

      .y_add2 {
        width: 130px;
        height: 25px;
        background: url(../assets//Ymain/mint4.png) center no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;

      }
    }

    .y_o {
      width: 85%;
      height: 35px;
      background: url(../assets//Ymain/mint2.png) center no-repeat;
      background-size: 100% 100%;
      text-align: center;
      padding-top: 10px;

      .yo_div {
        width: 85%;
        height: 15px;
        background: url(../assets//Ymain/o.png) center no-repeat;
        background-size: 100% 100%;
      }

      .yo_div1 {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
      }
    }
  }
}</style>
